




























import { Component, Vue, Prop } from "vue-property-decorator";
import Finish from "@/components/designer/sidebar/frame/Finish.vue";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore } from "@/mixins/store";
import {
  Finish as FinishModel,
  FinishDisplayName,
  FinishHandle
} from "@/models/products/finish";
import { GetFinishImage } from "@/mixins/helpers";
import { ProductLineName } from "@/models/products/collection";

@Component({
  components: {
    Finish
  }
})
export default class FinishGroup extends mixins(
  FrameStore,
  LayoutStore,
  GetFinishImage
) {
  @Prop() finishes!: FinishModel[];
  protected selected: FinishModel | null = null;

  created() {
    this.readStoreData();
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected to the first one in finish array
   */
  protected readStoreData(): void {
    if (!this.finish) {
      this.selected = this.finishes[0];
      this.addFinish(this.selected);
    } else {
      this.selected = this.finish;
    }
  }

  protected async select(finish: FinishModel): Promise<void> {
    this.selected = finish;
    const finialFinish =
      this.collection.handle !== ProductLineName.Greenwich &&
      this.collection.handle !== ProductLineName.GreenwichGSeries
        ? finish
        : { handle: FinishHandle.MS, display_name: FinishDisplayName.MS };
    this.addFinish(finish);
    await this.$viewer.ChangeFrameFinish(finish.handle);
    //if (this.finial) {
    //  this.addFinialFinish(finialFinish);
    //  await this.$viewer.ChangeFinial(this.finial, finialFinish.handle);
    //}
  }
}
